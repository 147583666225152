import { isPromise } from './jsutils.js';
export function mapMaybePromise(value, mapper, errorMapper) {
  if (isPromise(value)) {
    if (errorMapper) {
      try {
        return value.then(mapper, errorMapper);
      } catch (e) {
        return errorMapper(e);
      }
    }
    return value.then(mapper);
  }
  if (errorMapper) {
    try {
      return mapper(value);
    } catch (e) {
      return errorMapper(e);
    }
  }
  return mapper(value);
}