import { APP_INITIALIZER, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { InMemoryCache } from '@apollo/client/cache';
import { environment } from '@env/environment';
import { ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';

import { ApolloOptionsService } from './apollo-options.service';
import { relayStylePagination } from './pagination-caching';
import { AuthService } from '../shared/services/auth.service';

export function createApollo(authSrv: AuthService, apolloOptionsService: ApolloOptionsService, router: Router) {
  return async () => {
    console.log('Creating Apollo Client...');
    const graphql_frontend_url = `${environment.api.baseUrl}api/frontend-data/v1/graphql`;
    const frontend_backend_url = `${window.location.protocol}//${window.location.hostname}:${window.location.port}${graphql_frontend_url}`;
    const websocket_url =
      window.location.protocol === 'https:' ? frontend_backend_url.replace('https', 'wss') : frontend_backend_url.replace('http', 'ws');
    const graphql_history_url = `${environment.api.baseUrl}api/history/v1/graphql`;
    const history_backend_url = `${window.location.protocol}//${window.location.hostname}:${window.location.port}${graphql_history_url}`;
    const config = {
      frontend: {
        name: 'frontend',
        uri: frontend_backend_url,
        wsUrl: websocket_url
      },
      history: {
        name: 'history',
        uri: history_backend_url,
        wsUrl: null
      }
    };
    console.table(Object.values(config));

    try {
      await apolloOptionsService.create(
        config.frontend.name,
        config.frontend.uri,
        config.frontend.wsUrl,
        {
          watchQuery: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'ignore'
          },
          query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all'
          }
        },
        new InMemoryCache({
          addTypename: true, // default
          resultCaching: true, // default
          // resultCacheMaxSize: Math.pow(2, 12), // The limit of the number of result objects that will be retained in memory to speed up repeated reads to the cache. The default value is `Math.pow(2, 16)`.
          typePolicies: {
            Query: {
              fields: {
                documentClasses: relayStylePagination(['filter'])
              }
            }
          }
        })
      );

      await apolloOptionsService.create(
        config.history.name,
        config.history.uri,
        config.history.wsUrl,
        {
          watchQuery: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'ignore'
          },
          query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all'
          }
        },
        new InMemoryCache({}),
        true
      );

      await authSrv.initUserAndOrganisation();
      console.log('✅ Apollo Client initialized successfully.');
      apolloOptionsService.isOK = true;
    } catch (error) {
      console.error(`❌ Apollo Client failed to initialize: ${(error as Error).message}`);
      apolloOptionsService.isOK = false;
    }
  };
}

// Async apollo initialization according to: https://stackoverflow.com/questions/66095489/angular-usefactory-return-async-function-in-module
// Make apollo link with with scalars
@NgModule({
  imports: [ApolloModule],
  providers: [
    ApolloOptionsService,
    {
      provide: APP_INITIALIZER,
      useFactory: createApollo,
      deps: [AuthService, ApolloOptionsService, Router, HttpLink],
      multi: true
    }
  ]
})
export class GraphQLModule {}
