function isPromise(val) {
  return val?.then != null;
}
export function fakeRejectPromise(error) {
  if (isPromise(error)) {
    return error;
  }
  return {
    then() {
      return this;
    },
    catch(reject) {
      if (reject) {
        return fakePromise(reject(error));
      }
      return this;
    },
    finally(cb) {
      if (cb) {
        cb();
      }
      return this;
    },
    [Symbol.toStringTag]: 'Promise'
  };
}
export function fakePromise(value) {
  if (isPromise(value)) {
    return value;
  }
  // Write a fake promise to avoid the promise constructor
  // being called with `new Promise` in the browser.
  return {
    then(resolve) {
      if (resolve) {
        const callbackResult = resolve(value);
        if (isPromise(callbackResult)) {
          return callbackResult;
        }
        return fakePromise(callbackResult);
      }
      return this;
    },
    catch() {
      return this;
    },
    finally(cb) {
      if (cb) {
        const callbackResult = cb();
        if (isPromise(callbackResult)) {
          return callbackResult.then(() => value);
        }
        return fakePromise(value);
      }
      return this;
    },
    [Symbol.toStringTag]: 'Promise'
  };
}