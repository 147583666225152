import { Injectable } from '@angular/core';
import every from 'lodash/every';
import { isCountValidator } from 'src/app/graphql/data-typeguards';
import {
  DataType,
  DocumentClass,
  SelectionMode,
  CreatePredictionGQL,
  PredictedDocumentInput,
  PredictionCandidate,
  PredictionInput
} from 'src/app/graphql/frontend-data-graphql';
import { v4 as uuidv4 } from 'uuid';

@Injectable()
export class PredictionService {
  constructor(public createPrediction: CreatePredictionGQL) {}

  public countCurrentlyPresentChildren(documents: PredictedDocumentInput[], identifier: string) {
    return documents.filter(doc => doc.document_class_identifier == identifier).length;
  }

  fillUpMissingPredictedDocuments(documentClassChildren: DocumentClass[], documents: PredictedDocumentInput[]) {
    documentClassChildren.forEach(documentClass => {
      const min_count = (documentClass.expectations.validators || [])
        .map(v => v.kind)
        .filter(isCountValidator)
        .pop()?.count.minCount;

      if (min_count && this.countCurrentlyPresentChildren(documents, documentClass.identifier) < min_count) {
        documents.push(<PredictedDocumentInput>{
          document_class_identifier: documentClass.identifier,
          confidence: 1.0,
          id: uuidv4(),
          pages: [],
          prediction: null,
          value: null
        });
      }
    });
  }

  initializePrediction(documentClassChildren: DocumentClass[]) {
    const prediction = <PredictionInput>{
      candidates: [
        <PredictionCandidate>{
          documents: [],
          id: uuidv4(),
          score: 1.0
        }
      ]
    };

    const candidate = prediction.candidates[0];
    const documents = candidate.documents;
    const predictionType = this.getPredictionType(documentClassChildren);

    if (candidate.documents?.length == 0) {
      this.spawnDefaultFields(prediction, predictionType, documentClassChildren);
    } else {
      if (predictionType == 'extraction') {
        // Make sure we will up the remaining classes
        this.fillUpMissingPredictedDocuments(Object.values(documentClassChildren), documents);
      }
    }
    return prediction;
  }

  spawnDefaultFields(prediction: PredictionInput, predictionType: 'legacyChoice' | 'extraction', documentClassChildren: DocumentClass[]) {
    const candidate = prediction.candidates[0];

    if (predictionType == 'extraction') {
      Object.entries(documentClassChildren).forEach(([_, documentClass]) => {
        const min_count = (documentClass.expectations.validators || [])
          .map(v => v.kind)
          .filter(isCountValidator)
          .pop()?.count.minCount;
        for (let i = 0; i < (min_count || 1); i++) {
          candidate.documents.push(<PredictedDocumentInput>{
            document_class_identifier: documentClass.identifier,
            confidence: 1.0,
            id: uuidv4(),
            pages: [],
            prediction: null,
            value: null
          });
        }
      });
    }
  }

  getPredictionType(documentClassChildren: DocumentClass[]) {
    return every(
      documentClassChildren,
      c => c.expectations.selectionMode == SelectionMode.SelectionModeParent && c.expectations.possibleValue == DataType.DataTypeNone
    )
      ? 'legacyChoice'
      : 'extraction';
  }
}
