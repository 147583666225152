import { mapMaybePromise } from './map-maybe-promise.js';
export function isIterableObject(value) {
  return value != null && typeof value === 'object' && Symbol.iterator in value;
}
export function isObjectLike(value) {
  return typeof value === 'object' && value !== null;
}
export function isPromise(value) {
  return value?.then != null;
}
export function promiseReduce(values, callbackFn, initialValue) {
  let accumulator = initialValue;
  for (const value of values) {
    accumulator = mapMaybePromise(accumulator, resolved => callbackFn(resolved, value));
  }
  return accumulator;
}
export function hasOwnProperty(obj, prop) {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}