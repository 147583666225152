import { makeEnvironmentProviders } from '@angular/core';
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

/**
 * Custom Route Reuse Strategy that reuses routes configured with `{ data: { reuse: true } }` in the route configuration.
 * only applied to routes that have a unique subpath excluding `""` and redirects.
 */
export class CustomReuseStrategy implements RouteReuseStrategy {
  private handlers: { [key: string]: DetachedRouteHandle } = {};
  private defaultReuse: boolean;
  private reuseKey: string;

  constructor(defaultReuse = false, reuseKey = 'reuse') {
    this.defaultReuse = defaultReuse;
    this.reuseKey = reuseKey;
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    // if true: leave current route and store in for later
    const hash = this.getHash(route);
    const shouldDetach = !route.component
      ? false
      : route.data[this.reuseKey] !== undefined
        ? route.data[this.reuseKey] === true
        : this.defaultReuse;
    if (shouldDetach) console.debug(`[CustomReuseStrategy] should detach ${hash}`);
    return shouldDetach;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    // only triggered when shouldDetach was true for the route
    const hash = this.getHash(route);
    this.handlers[hash] = handle;
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    // only triggered if shouldDetach was true for this route before
    // if true: try to retrieve state
    const hash = this.getHash(route);
    const shouldAttach = Boolean(this.handlers[hash]);
    if (shouldAttach) console.debug(`[CustomReuseStrategy] should attach ${hash}`);
    return shouldAttach;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    // only triggered when shouldAttach was true for the route
    const hash = this.getHash(route);
    return this.handlers[hash];
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    const shouldReuseRoute = future.routeConfig === curr.routeConfig;
    if (shouldReuseRoute) console.debug(`[CustomReuseStrategy] should reuse future route ${this.getHash(future)}`);
    return shouldReuseRoute;
  }

  private getHash(route: ActivatedRouteSnapshot): string {
    // combination of component name and url segments should be unique at the moment.
    // route.url itself is not unique because it is not the absolute url but only the configured `path` in the route config.
    return route.toString();
  }
}

export function provideRouteReuse(options?: { defaultReuse?: boolean; reuseKey?: string }) {
  return makeEnvironmentProviders([
    {
      provide: RouteReuseStrategy,
      useValue: new CustomReuseStrategy(options?.defaultReuse, options?.reuseKey)
    }
  ]);
}
