import { Injectable } from '@angular/core';

import { Organization } from './graphql/frontend-data-graphql';
import { AuthService } from './shared/services/auth.service';

function getWindow(): any {
  return window;
}

export abstract class NgxFreshdeskWebwidgetConfig {
  abstract widgetId: number;
  abstract locale: string;
  abstract callback(FreshworksWidget: any): any;
}

@Injectable()
export class FreshdeskService {
  constructor(private auth: AuthService) {
    const ngxFreshdeskWebwidgetConfig = {
      widgetId: '101000004205',
      locale: 'de',
      callback: (FreshworksWidget: any) => {
        FreshworksWidget('identify', 'ticketForm', {
          email: this.auth.user?.email,
          name: `${this.auth.user?.first_name} ${this.auth.user?.last_name}`,
          custom_fields: { platform: window.location.origin }
        });
        FreshworksWidget('hide', 'ticketForm', ['email', 'name', 'custom_fields.cf_document_url']);
        FreshworksWidget('hide', 'launcher');
      }
    };
    if (!ngxFreshdeskWebwidgetConfig.widgetId) {
      throw new Error('Missing widgetId. Please set in app config via FreshdeskWidgetProvider!');
    }
    const window = getWindow();

    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://euc-widget.freshworks.com/widgets/${ngxFreshdeskWebwidgetConfig.widgetId}.js`;

    window.fwSettings = {
      widget_id: ngxFreshdeskWebwidgetConfig.widgetId,
      locale: ngxFreshdeskWebwidgetConfig.locale
    };

    window.FreshworksWidget ||
      (function () {
        if ('function' != typeof window.FreshworksWidget) {
          // eslint-disable-next-line prefer-const
          let n: any = function () {
            // eslint-disable-next-line prefer-rest-params
            n['q'].push(arguments);
          };
          (n['q'] = []), (window.FreshworksWidget = n);
        }
      })();

    script.onload = function (event) {
      try {
        ngxFreshdeskWebwidgetConfig.callback(window.FreshworksWidget);
      } catch (error) {
        console.log('error.: ', error);
      }
    };

    script.onerror = function (event) {
      console.log('error Onerror.: ', event);
    };

    document.body.append(script);
  }

  setFreshworkWidgetVisibility(org: Organization) {
    if (org.show_freshdesk_widget) {
      this.FreshworksWidget('show', 'launcher');
    } else {
      this.FreshworksWidget('hide', 'launcher');
    }
  }

  get FreshworksWidget(): any {
    const window = getWindow();
    return window.FreshworksWidget;
  }
}
