import every from 'lodash/every';
import { DocumentState, Document, SelectionMode, DocumentClass, DataType } from 'src/app/graphql/frontend-data-graphql';

import { VerificationComponentType } from '../document.reducer';

export function getVerificationType(document: Document, children: DocumentClass[]): VerificationComponentType {
  let relevantChildren = children.filter(c => !c.deletedAt);

  // if all classes are deleted, then do not filter them after all.
  // this might allow viewing of predictions on deleted classes.
  if (relevantChildren.length === 0) relevantChildren = children;

  if (!document) return 'other';
  if (document.state == DocumentState.AwaitingExportVerification) return 'export';
  if (relevantChildren.length === 0) return 'other';

  if (every(relevantChildren, c => c.expectations.selectionMode === SelectionMode.SelectionModePages)) return 'pages';

  if (
    every(
      relevantChildren,
      c => c.expectations.selectionMode == SelectionMode.SelectionModeParent && c.expectations.possibleValue === DataType.DataTypeNone
    )
  )
    return 'simple';

  if (
    every(
      relevantChildren,
      c =>
        c.expectations.selectionMode === SelectionMode.SelectionModeCutout ||
        c.expectations.selectionMode === SelectionMode.SelectionModeParent
    )
  )
    return 'extraction';

  return 'other';
}
