import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApolloCache, InMemoryCache } from '@apollo/client/cache';
import { ApolloClientOptions, ApolloLink, DefaultOptions, Operation, FetchResult, Observable } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError, ErrorResponse } from '@apollo/client/link/error';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';
import { AsyncExecutor } from '@graphql-tools/utils';
import { schemaFromExecutor, wrapSchema } from '@graphql-tools/wrap';
import { Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { withScalars } from 'apollo-link-scalars';
import { getOperationAST, print } from 'graphql';
import { GraphQLDateTime, GraphQLJSON } from 'graphql-scalars';
import { createClient, ClientOptions, Client } from 'graphql-ws';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

import { AuthService } from '../shared/services/auth.service';

const removeTypenameLink = removeTypenameFromVariables();
class WebSocketLink extends ApolloLink {
  public client: Client;

  constructor(options: ClientOptions) {
    super();
    this.client = createClient(options);
  }

  public override request(operation: Operation): Observable<FetchResult> {
    return new Observable(sink => {
      return this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: sink.error.bind(sink)
        }
      );
    });
  }
}

@Injectable({ providedIn: 'root' })
export class ApolloOptionsService {
  public isOK = false;
  public clientStatus = new BehaviorSubject<boolean>(false);

  constructor(
    private httpLink: HttpLink,
    public authService: AuthService,
    private http: HttpClient,
    private apollo: Apollo
  ) {}

  async canActivate() {
    if (!this.isOK) {
      await this.authService.logout();
      return false;
    }
    return true;
  }

  async create(
    name: string,
    uri: string,
    wsUrl: string | null,
    defaultOptions: DefaultOptions,
    cache: ApolloCache<any>,
    createDummy = false
  ) {
    if (!this.authService.token) {
      throw new Error('No token yet, cannot create graphql endpoint');
    }
    if (!uri) {
      throw new Error('No graphql uri set for this client');
    }
    const basic = setContext(() => ({
      headers: {
        Accept: 'charset=utf-8'
      }
    }));

    const auth = setContext(() => {
      if (this.authService.token === null) {
        console.warn('No token available, skipping graphql creation');
        return {};
      } else {
        return {
          headers: {
            authorization: `Bearer ${this.authService.token}`
          }
        };
      }
    });

    const errorLink = onError((error: ErrorResponse) => {
      if (error.graphQLErrors) {
        error.graphQLErrors.forEach(({ message, locations, path }) => {
          if (message == 'jwt expired') return;
          console.error(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`);
          if (message == 'Unauthorized') {
            // this.authService.logout();
          }
        });
      }

      if (error.networkError) {
        console.error(`[Network error]: ${JSON.stringify(error.networkError)}`);
      }
    });

    const http = this.httpLink.create({ uri });

    // Scalar map to transform e.g. date strings into javascript Dates
    const typesMap = {
      MyDateTime: GraphQLDateTime,
      JSONString: GraphQLJSON
    };

    // Executor to retrieve schema for introspection below
    const executor: AsyncExecutor = async ({ document, variables }) => {
      const query = print(document);
      const fetchResult = await firstValueFrom(
        this.http.post(uri, JSON.stringify({ query, variables }), {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${this.authService.token}`
          }
        })
      );
      return fetchResult as any;
    };

    try {
      const schema = wrapSchema({ schema: await schemaFromExecutor(executor) });

      let wsLink: ApolloLink = ApolloLink.empty();
      let authMiddleware: any;
      if (wsUrl) {
        const ws = new WebSocketLink({
          url: () => `${wsUrl}?org=${this.authService.organization$.value?.identifier}&token=${this.authService.token}`,
          keepAlive: 10000,
          lazy: false, // make the client connect immediately
          on: {
            connected: () => this.clientStatus.next(true),
            closed: () => this.clientStatus.next(false),
            error: err => {
              console.error(err);
            }
          },
          connectionParams: () => {
            if (this.authService.token === null) {
              return {
                authorization: 'none'
              };
            } else {
              return {
                authorization: `Bearer ${this.authService.token}`
              };
            }
          }
        });

        let wsOrg = this.authService.organization$.value?.identifier;
        const authMiddleware = setContext(() => {
          const currentOrg = this.authService.organization$.value?.identifier;
          if (currentOrg && currentOrg != wsOrg) {
            ws.client.terminate();
            wsOrg = currentOrg;
          }
          return ws;
        });
        wsLink = ApolloLink.from([withScalars({ schema, typesMap }), authMiddleware, ws as any]);
      }

      const split = ApolloLink.split(
        // 3
        operation => {
          const operationAST = getOperationAST(operation.query, operation.operationName);
          return !!operationAST && operationAST.operation === 'subscription';
        },
        wsLink,
        ApolloLink.from([withScalars({ schema, typesMap }), http])
      );

      this.apollo.create(
        {
          link: ApolloLink.from([errorLink, removeTypenameLink, basic, auth, split]),
          cache,
          defaultOptions
          // For future use:
          // connectToDevTools: true
        },
        name
      );
    } catch (err) {
      console.error(`Der Service ${name}(${uri}) konnte nicht erreicht werden.`);
      if (createDummy) {
        this.apollo.create(
          {
            link: ApolloLink.from([errorLink]),
            cache: new InMemoryCache({})
          },
          name
        );
      } else {
        throw err;
      }
    }
  }
}
