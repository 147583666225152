import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { GraphQLError } from 'graphql';
import { Dictionary, keyBy } from 'lodash';
import { Document, DocumentClass } from 'src/app/graphql/frontend-data-graphql';

import { DocumentApiActions, DocumentUIActions } from './document.actions';

export type VerificationComponentType = 'simple' | 'pages' | 'extraction' | 'other' | 'export';

export const documentsFeatureKey = 'document';

export interface DocumentState extends EntityState<Document> {
  activeDocument: Document | null;
  updatedDocument: Document | null;
  parentDocuments: Document[];
  isLoadingForActiveDocument: boolean;
  verificationType: VerificationComponentType;
  documentClassesForActiveDocument: DocumentClass[];
  errors: readonly GraphQLError[];
  extraction: null | {
    documentClasses: Dictionary<DocumentClass>;
    focussedDocumentClass: DocumentClass | null;
  };
}

export const adapter: EntityAdapter<Document> = createEntityAdapter<Document>();

export const initialState: DocumentState = adapter.getInitialState({
  activeDocument: null,
  updatedDocument: null,
  parentDocuments: [],
  documentClassesForActiveDocument: [],
  isLoadingForActiveDocument: true,
  verificationType: 'other',
  errors: [],
  extraction: null
});

export const documentReducer = createReducer(
  initialState,
  on(
    DocumentUIActions.select,
    (state, { id }): DocumentState => ({
      ...state,
      activeDocument: id ? state.activeDocument : null,
      updatedDocument: null,
      parentDocuments: [],
      isLoadingForActiveDocument: id ? true : false,
      verificationType: 'other'
    })
  ),
  on(
    DocumentUIActions.reload,
    (state, { id }): DocumentState => ({ ...state, isLoadingForActiveDocument: true, verificationType: 'other', updatedDocument: null })
  ),
  on(DocumentApiActions.updatedOne, (state, { item }): DocumentState => ({ ...state, updatedDocument: item })),
  on(
    DocumentApiActions.findOneSucceeded,
    (state, { item, documentClasses, verificationType }): DocumentState => ({
      ...state,
      activeDocument: item,
      isLoadingForActiveDocument: false,
      documentClassesForActiveDocument: documentClasses,
      verificationType
    })
  ),
  on(
    DocumentApiActions.findParentsSucceeded,
    (state, { documentId, parents }): DocumentState => ({
      ...state,
      parentDocuments: parents
    })
  ),
  on(
    DocumentApiActions.lockOneSucceeded,
    (state, { lockedBy, lockedUntil }): DocumentState => ({
      ...state,
      activeDocument: { ...state.activeDocument!, locked_by: lockedBy, locked: true, locked_until: lockedUntil, locked_at: new Date() }
    })
  ),
  on(
    DocumentApiActions.requestFailed,
    (state, { errors }): DocumentState => ({
      ...state,
      isLoadingForActiveDocument: false,
      errors
    })
  ),
  on(
    DocumentUIActions.initExtractionVerification,
    (state, { documentClasses }): DocumentState => ({
      ...state,
      extraction: {
        documentClasses,
        focussedDocumentClass: null
      }
    })
  )
);

export const documentsFeature = createFeature({
  name: documentsFeatureKey,
  reducer: documentReducer,
  extraSelectors: ({ selectDocumentState, selectExtraction, selectDocumentClassesForActiveDocument }) => ({
    selectUpdateAvailableForActiveDocument: createSelector(selectDocumentState, (state: DocumentState) => Boolean(state.updatedDocument)),
    selectExtractionClasses: createSelector(selectExtraction, state => state?.documentClasses ?? {}),
    selectDocumentClassesMapForActiveDocument: createSelector(selectDocumentClassesForActiveDocument, documentClasses =>
      keyBy(documentClasses, dc => dc.identifier)
    )
  })
});

export const {
  selectIds,
  selectEntities,
  selectActiveDocument,
  selectIsLoadingForActiveDocument,
  selectVerificationType,
  selectDocumentClassesForActiveDocument,
  selectDocumentClassesMapForActiveDocument,
  selectUpdateAvailableForActiveDocument,
  selectUpdatedDocument,
  selectParentDocuments,
  selectExtractionClasses
} = documentsFeature;
